<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        lg="8"
        md="8"
        sm="8"
        xs="12"
      >
        <v-row>
          <v-card
            rounded="lg"
            elevation="10"
          >
            <v-img
              contain
              src="@/assets/polizadesoporte2022.jpg"
            />
          </v-card>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        lg="4"
        md="4"
        sm="4"
        xs="12"
      >
        <v-card
          elevation-10
          rounded="lg"
          class="transparent elevation-3 ma-2 pa-2 "
          height="100%"
        >
          <!-- <div align="center" class="mb-5">
            <img src="soporte1.png" alt="No se reconoce la imagen" style="width: 40%; height: 100%;">
          </div>
          -->

          <!-- <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template> -->

          <v-img
            height="150"
            contain
            src="@/assets/logo.jpg"
          />

          <v-card-title>Login de Usuarios</v-card-title>

          <v-card-text>
            <v-form>
              <v-text-field
                v-model="email"
                prepend-icon="email"
                outline
                label="Correo"
                type="email"
              />

              <v-text-field
                v-model="password"
                prepend-icon="lock"
                :append-icon="show1 ? 'visibility' : 'visibility_off'"
                :type="show1 ? 'text' : 'password'"
                label="contraseña"
                hint="At least 8 characters"
                counter
                outline
                autocomplete="on"
                @click:append="show1 = !show1"
              />
            </v-form>
          </v-card-text>
          
          <v-card-actions>
            <v-spacer />
           
            <v-btn
              color="secondary "
              dark
              :to="{name: 'registro'}"
            >
              Registrarse
            </v-btn>
            
            <v-spacer />
            
            <v-btn
              color="primary"
              dark
              @click="ingresar"
            >
              Login
            </v-btn>
            <v-spacer />
          </v-card-actions>
         
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              dark
              :to="{name: 'olvidacontra'}"
            >
              Cambiar contraseña
            </v-btn>
            <v-spacer />
          </v-card-actions>
          
          <p class="mx-4">
            V 1.0.2
          </p>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
        md="6"
        sm="6"
        xs="12"
      >
        <v-card
          class="elevation-3 ma-1 pa-1 "
          height="100%"
        >
          <!--  <v-card-title primary-title>
            Sait Tienda en Linea
          </v-card-title> -->

          <v-card
            target="_blank"
            href="https://www.tiendaenlinea.pro/"
          >
            <v-img
              class="ma-1"
              height="150"
              contain
              src="@/assets/logotienda2.png"
            />
          </v-card>

          <v-divider />

          <!--  <v-img class="ma-3"
            height="150" contain
            src="@/assets/saitsync.png"
          ></v-img>
           <v-divider></v-divider> -->
          <v-card
            target="_blank"
            href="https://boveda.sait.mx/"
          >
            <v-img
              class="ma-1"
              height="150"
              contain
              src="@/assets/logoboveda.png"
            />
          </v-card>

          <v-divider />
          <v-card
            target="_blank"
            href="https://www.youtube.com/watch?v=BU6Oz6U9onU"
          >
            <v-img
              class="ma-1"
              height="150"
              contain
              src="@/assets/logonomina.png"
            />
          </v-card>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
        md="6"
        sm="6"
        xs="12"
      >
        <v-card
          class="elevation-3 ma-1 pa-1 "
          height="100%"
        >
          <v-card
            class="elevation-3"
            target="_blank"
            href="https://sait.mx/blog/solicita-en-sait-tu-autofacturador-en-linea/"
          >
            <v-img
              class="ma-1"
              height="150"
              contain
              src="@/assets/autofacturador.jpg"
            />
          </v-card>
          <v-divider />
          <v-card
            target="_blank"
            href="https://capacitacion.app"
          >
            <v-img
              class="ma-1"
              height="150"
              contain
              src="@/assets/capacitacion.png"
            />
          </v-card>
          <v-card>
            <v-img
              class="ma-1"
              height="150"
              contain
              src="@/assets/ventaenruta.png"
            />
          </v-card>
        </v-card>
      </v-col>


      <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        top
        color="error"
      >
        Datos no encontrados
        <v-btn
          color="white"
          text
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  data: () => ({
    snackbar: false,
    show1: false,
    password: '',
    email:'',
      
  }),

  computed:{
    ...mapGetters('login',['getdatosUsuario'])
  },

  methods:{
    ...mapActions('login',['validarUser','GetInfoUser','salir']),
    ...mapActions('ordenesAse',['cargar_info_ordenes','verOrden']),
    ...mapActions('ordenes',['traerOrdenesUsuario']),

     
    ingresar(){
      //se declara el md5 para poder ser utilizado
      var md5 = require('md5');
      //se crea un objeto con los datos
      var usuario = {email: this.email, password: md5(this.password)}
      var payloadlogin = { "Username": this.email, "Password": md5(this.password)}

      this.validarUser(payloadlogin).then(response => {
        let token = response.token
        // console.log("token", token)
        this.$store.dispatch("guardarToken", token)
          
        // Mando a consultar la informacion del cliente
        this.GetInfoUser(usuario).then(response => {

          console.log("GetInfoUser",response)

          if(response === false){
            this.text     = "No existe el usuario o sus datos son incorrectos"
            this.snackbar = true
            return
          } else {
            // console.log("usuario")
            this.$store.dispatch("guardarNivel", 'USUARIO')

            this.$router.push( {name: 'midia'})
          }
        })
      }).catch(error =>{
        // Si la respuesta es 401 muestro mensaje
        if(error.status=== 401){
          this.text     = "No existe el usuario o sus datos son incorrectos"
          this.snackbar = true
        }
      })
    },
  },
}
</script>